import React from 'react';
import './App.css';
import MainContainer from './containers/MainContainer';

function App() {
  return (

      <MainContainer />

  );
}

export default App;
